/** @jsx jsx */
import { Fragment, FC } from 'react'
import { jsx } from '@emotion/core'

import Helmet from '../components/HelmetHelper'
import Container from '../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../components/content/Text'
import { THEME_CITRON } from '../constants'
import Slide, { SLIDE_LAYOUT_MOCK_FULL_SCREEN } from '../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../components/content/Layout'
import { provideAccentTheme } from '../context/AccentThemeContext'
import { CopyButtonInline } from '../components/CopyButton'
import { LinkButton } from '../components/Button'

type Props = {
	accentTheme: string
}

const StudentsPage: FC<Props> = ({ accentTheme }) => {
	return (
		<Fragment>
			<Helmet>
				<title>Student Session</title>
				<meta content="Student Session" property="og:title" />
				<meta content="Student Session" name="twitter:title" />
				<meta
					name="description"
					content="Providing students with a window into how Free Association engages with candidates across Strategy, Design, and UX Engineering."
				/>
				<meta
					property="og:description"
					content="Providing students with a window into how Free Association engages with candidates across Strategy, Design, and UX Engineering."
				/>
				<meta
					content="https://freeassociation.com/share/Student-Session-Share-Img_Orange.png"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/Student-Session-Share-Img_Orange.png"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--headline">
								Free Association
								<br />
								Student Session
							</h1>
							<p className="--eyebrow">July 14th, 4-5pm EST</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									Members of the Free Association team would like to provide a
									window into how we engage with candidates across Strategy,
									Design, and UX Engineering, as well as personal anecdotes
									about developing a career in the industry today.
								</p>
								<p>
									We have all experienced the early days of starting off in a
									chosen field. It’s exciting and intimidating all at once. How
									do you make a meaningful first step into what seems like a
									very competitive and evolving industry? We would love to help
									you on your journey.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Discussion Topics">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul>
									<li>Personal Career Stories</li>
									<li>Our Hiring Process</li>
									<li>What We Look For in Candidates</li>
									<li>Q&amp;A</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Participants">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled">
									<li>
										<strong>Alexa Liley</strong>, Product Director and Panel
										Moderator
									</li>
									<li>
										<strong>Andy Titus</strong>, Managing Partner
									</li>
									<li>
										<strong>Jessica Walker</strong>, Managing Director
									</li>
									<li>
										<strong>Kris Piliero</strong>, Senior Director of People
										Operations
									</li>
									<li>
										<strong>Kyle Truscott</strong>, UX Engineering Director
									</li>
									<li>
										<strong>Will Anderson</strong>, Design Lead
									</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="">
							<LinkButton
								layout="block"
								to="https://us02web.zoom.us/webinar/register/WN_ePFVgrniRzquIzGqcD8TUA"
							>
								Register
							</LinkButton>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="About Us">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									Free Association is a design agency focused on digital
									products and brand systems. We’ve built longstanding
									partnerships with companies like Google, Facebook, Toyota,
									eBay, PayPal, Waze and a range of new ventures. We pursue
									meaningful work and surround ourselves with talented,
									inspiring people.
								</p>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--headline">Let’s work together.</p>
							<p className="--small">
								<CopyButtonInline
									copiedMessage="Copied"
									copyValue="hello@freeassociation.com"
								>
									hello@freeassociation.com
								</CopyButtonInline>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</Fragment>
	)
}

export default provideAccentTheme(StudentsPage, THEME_CITRON)
