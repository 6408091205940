/** @jsx jsx */
import { jsx } from '@emotion/core'
import hex2rgba from 'hex2rgba'

import { colors, typeRamp } from '../constants'
import { RouterLink } from './content/Link'

export const buttonStyleInline = {
	cursor: 'pointer',
	position: 'relative',
	display: 'inline-flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '1.25rem 3rem',
	border: `2px solid ${colors.ink}`,
	background: colors.ink,
	color: colors.white,
	...typeRamp.med_16,

	'&:hover': {
		color: colors.white,
		background: colors.ink,
	},
	'&:focus': {
		outline: 'none',
		boxShadow: `0 0 4px 4px ${hex2rgba(colors.periwinkle)}`,
	},

	'& > span': {
		display: 'inline-block',
		position: 'relative',
		'&::after': {
			content: '" "',
			position: 'absolute',
			left: 0,
			bottom: 0,
			height: 1,
			width: '100%',
			background: colors.white,
			transformOrigin: 'left top',
			transform: 'scaleX(0)',
			transition: 'transform 400ms cubic-bezier(0.19, 1, 0.22, 1)',
		},
	},

	'&:hover > span::after': {
		transform: 'scaleX(1)',
	},
}

export const buttonStyleBlock = {
	...buttonStyleInline,
	display: 'flex',
	width: '100%',
}

export default ({ children, layout, ...props }) => {
	return (
		<button
			css={layout === 'block' ? buttonStyleBlock : buttonStyleInline}
			{...props}
		>
			<span>{children}</span>
		</button>
	)
}

export const LinkButton = ({ children, layout, ...props }) => {
	return (
		<RouterLink
			css={layout === 'block' ? buttonStyleBlock : buttonStyleInline}
			{...props}
		>
			<span>{children}</span>
		</RouterLink>
	)
}
